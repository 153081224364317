import "./App.css";
import Payment from "./Payment";
import Tokenize from "./Tokenize";
import Completion from "./Completion";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";

function App() {
	return (
		<main>
			<BrowserRouter>
				<Routes>
					<Route
						path="/payment/:amount/:description/:email/:token/:doctor/:method"
						element={<Payment />}
					/>
					<Route
						path="/tokenize/:item/:method"
						element={<Tokenize />}
					/>
					<Route path="/completion" element={<Completion />} />
					{/* <Route path="/completion/:token" element={<Completion />} /> */}
					<Route path="/completion/:payer" element={<Completion />} />
					<Route path="/checkout" element={<CheckoutForm />} />
				</Routes>
			</BrowserRouter>
		</main>
	);
}

export default App;
