import { useParams } from "react-router-dom";
// import nodemailer from 'nodemailer';
// import nodemailerTransport from 'nodemailer-mailgun-transport';

function Completion(props) {
	const { payer } = useParams();

	// const auth ={
	// 	auth: {
	// 		api_key: '98d509ed52b2eeb816c5567a0d2e9449-4c205c86-cc760001',
	// 		domain: 'mg.vitagelab.com'
	// 	}
	// }

	// const nodemailerMailgun = nodemailer.createTransport(nodemailerTransport(auth));

	// nodemailerMailgun.sendMail({
	// 	from: 'noreply@vitagelab.com',
	// 	to: 'mmoreno@vitagelab.com',
	// 	subject: 'Probando mailer desde payments',
	// 	html: `<p>HI esta es una prueba</p>`,
	// 	text: 'Mailgun rocks, pow pow!'
	// }, function (err, info) {
	// 	if (err) {
	// 		console.log('Error: ' + err);
	// 	}
	// 	else {
	// 		console.log('Response: ' + info);
	// 	}
	// });

	return (
		<>
			<img src="/static/logo/vitagelabpro.png" alt="" width={300} />
			<br /><br />
			<i className="icofont-check-circled icofont-4x success"></i>
			<h1>Payment successful</h1>
			<p>
				Thank you for your preference! Your order has been received and is
				in progress. You will receive an email on every update.
			</p>
			{ payer === 'patient' && (
				<>
					<p>
						You may close this tab now, or...
					</p>
					<p>
						<a href="javascript:window.close()" class="button">
							Click here to finish
						</a>
					</p>
				</>
			)}

			{ payer === 'doctor' && (
				<>
					<p>
						<a href="https://probackend.vitagelab.com/pro-dashboard" class="button">
							Return to Dashboard
						</a>
					</p>
				</>
			)}
		</>
	);
}

export default Completion;
