import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";

function Tokenize() {
	const [encrptedData, setEncrptedData] = useState("");
	const [decrptedData, setDecrptedData] = useState("");
	const { item, method } = useParams();
	useEffect(() => {
		const secretPass = "XkhZG4fW2t2W";
		const substitutionsAfterEncryption = new Map([
			["+", "-"],
			["/", "_"],
			["=", "~"],
		]);
		const substitutionsBeforeDecryption = new Map([
			["-", "+"],
			["_", "/"],
			["~", "="],
		]);
		const decryptData = (cipher) => {
			const toDecrypt = cipher.replace(
				/[-_~]/g,
				(match) => substitutionsBeforeDecryption.get(match) ?? match
			);
			const bytes = CryptoJS.AES.decrypt(toDecrypt, secretPass);
			// console.log("bytes", bytes);
			const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
			// console.log("data", data);
			// return;

			setDecrptedData(data);
		};

		const encryptData = (plaintext) => {
			const data = CryptoJS.AES.encrypt(
				JSON.stringify(plaintext),
				secretPass
			).toString();
			const dataready = data.replace(
				/[+/=]/g,
				(match) => substitutionsAfterEncryption.get(match) ?? match
			);

			setEncrptedData(dataready);
		};
		if (method === "en" && item !== null) {
			encryptData(item);
		}
		if (method === "de" && item !== null) {
			decryptData(item);
		}
	}, [item, method]);

	return (
		<>
			<h1>Encrypted/Descripted</h1>
			<p>{encrptedData}</p>
			<p>{decrptedData}</p>
		</>
	);
}

export default Tokenize;
